import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';
import libConfig from '../../../lib/config';
import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import { LoadSpinner } from '../../../widgets';

export default class PLan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            totalData: 0,
            valuesFilter: {},
            services: []
        };
        this.page = 1;
        this.limit = 20;
    }
    componentWillMount = async () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let query = `limit=${this.limit}&page=${this.page}`;
            for (let [key, value] of Object.entries(this.state.valuesFilter)) {
                query += `&${key}=${value}`;
            }
            let res = await fetchData({
                url: `api/v2/ow/transaction?${query}`
            });

            this.setState({ listData: res.data, loading: false, totalData: res.total, services: res.services });
        } catch (err) {
            this.setState({ loading: false });
        }
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 300)
    }
    handleFilter = (e) => {
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter }, () => { this.getListData('reset') });
    }
    updateTransaction = async (values) => {
        this.setState({ loadingForm: true });
        try {
            let res = await fetchData({
                url: `api/v2/ow/transaction/${values._id}`,
                method: 'post',
                data: values
            });
            this.setState({ visibleForm: false });
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công!',
                title: 'Xác nhận thanh toán',
                type: 'success'
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xác nhận thanh toán',
                type: 'error'
            })
        }
        this.setState({ loadingForm: false });
    }
    render() {
        return (
            <div className="content">
                <Modal
                    title="Xác nhận thanh toán"
                    footer={null}
                    onCancel={() => this.setState({ visibleForm: false })}
                    visible={this.state.visibleForm}
                    centered
                >
                    <Form
                        onFinish={this.updateTransaction}
                        ref={(evt) => this.form = evt }
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="note" label="Ghi chú">
                            <Input.TextArea style={{minHeight: "150px"}}/>
                        </Form.Item>
                        <div className="d-flex justify-content-end">
                            <Button danger onClick={() => this.setState({ visibleForm: false })} disabled={this.state.loadingForm}>Đóng</Button>
                            <Button htmlType="submit" type="primary" className="ml-2" loading={this.state.loadingForm}>Xác nhận</Button>
                        </div>
                    </Form>
                </Modal>
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Thanh toán</h3>
                            </div>
                            <div className="block-header pt-0 pb-0">
                                <div className="d-flex mb-10">
                                    <button className={`btn btn-primary btn-sm d-flex`}>
                                        Tất cả: {this.state.totalData}
                                    </button>
                                    <Tooltip title="Làm mới">
                                        <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={this.getListData}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="block-header">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <input placeholder="Tìm theo mã" style={{ width: "250px", marginRight: "10px" }} onChange={this.handleFilterSearch} name="code" className="form-control" />
                                    <input placeholder="Tên người dùng" style={{ width: "250px", marginRight: "10px" }} onChange={this.handleFilterSearch} name="username" className="form-control" />
                                    <select className="form-control" name="status" onChange={this.handleFilterSearch}>
                                        <option value="">Trạng thái</option>
                                        <option value={0}>Chưa thanh toán</option>
                                        <option value={1}>Đã thanh toán</option>
                                    </select>
                                    <select className="form-control ml-10" name="service_id" onChange={this.handleFilterSearch}>
                                        <option value="">Nguồn</option>
                                        {
                                            this.state.services.map((item, i) => {
                                                return <option value={item._id} key={i}>{item.domain}</option>
                                            })
                                        }
                                    </select>
                                    
                                    {/* <Button style={{ marginRight: "10px" }} type="primary" icon={<ReloadOutlined />} onClick={() => this.getListData()} /> */}
                                </div>
                                <Pagination
                                    total={this.state.totalData}
                                    showSizeChanger
                                    pageSize={this.limit}
                                    pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                    current={this.page}
                                    onShowSizeChange={(current, size) => {
                                        this.limit = size;
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                />
                            </div>
                            <div className="block-content">
                                <div className="table-responsive">
                                    <LoadSpinner show={this.state.loading} />
                                    <table className="table table-striped table-vcenter table-text-center">
                                        <thead>
                                            <tr>
                                                {/* <th>#</th> */}
                                                <th>Mã</th>
                                                <th className="text-left">Gói</th>
                                                {/* <th>Số tiền</th> */}
                                                <th className="text-left">Người dùng</th>
                                                <th>Trạng thái</th>
                                                <th className="text-left">Thời gian</th>
                                                {/* <th>Thanh toán</th> */}
                                                <th>Ghi chú</th>
                                                <th>Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                    return <tr key={i}>
                                                        {/* <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td> */}
                                                        <td >
                                                            <p className="mb-5">{item.code}</p>
                                                            <span className="badge badge-light">{item.service ? item.service.domain : item.domain}</span>
                                                        </td>
                                                        <td className="text-left">
                                                        {/* <p className="text-nowrap text-muted mb-2">
                                                                <i className="fa fa-user"></i> {
                                                                    item.user ? item.user.display_name : ""
                                                                }
                                                            </p> */}
                                                            <p className="mb-1 text-danger"><b><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} />đ</b></p>
                                                            <p className="mb-0">
                                                            {item.type_service == 'spam_uid' ? <span className="badge badge-danger">Reinbox</span> :
                                                                <span className="badge badge-primary">Scan</span>}
                                                            </p>
                                                            {
                                                                item.options ? item.options.map((option, i) => {
                                                                    let desc = "";
                                                                    let title = option.title;
                                                                    let number_month = option.number_month < 1 ? `${option.number_month * 30} ngày` : `${option.number_month} tháng`;
                                                                    if (option.function == 'spam_profile') {
                                                                        if (option.is_vip) {
                                                                            desc = `${number_month}`;
                                                                            title = title ? `Vip ${title.toLocaleLowerCase()}` : '';
                                                                        } else {
                                                                            desc = `${option.number_page} nick / ${number_month}`;
                                                                        }
                                                                    } else if (option.function == 'spam_to_fanpage') {
                                                                        desc = `${option.num_message} tin nhắn / ${number_month}`;
                                                                    } else {
                                                                        desc = `${number_month}`;
                                                                    }
                                                                    return <p key={i} className="m-0"><strong>- {title}</strong> ({desc})</p>
                                                                }) : null
                                                            }
                                                            {
                                                                item.package ? <p className="m-0"><strong>- {item.package.name}</strong></p> : null
                                                            }
                                                        </td>
                                                        {/* <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} />đ</td> */}
                                                        <td className="text-left">
                                                            <span className="text-nowrap">{
                                                                item.user ? item.user.display_name : ""
                                                            }</span>
                                                        </td>
                                                        <td>
                                                            {
                                                                item.status == 1 ?
                                                                    <span className="badge badge-success">Đã thanh toán</span>
                                                                    : <span className="badge badge-primary">Chờ thanh toán</span>
                                                            }
                                                        </td>
                                                        <td className="text-left">
                                                            <p className="mb-2">{moment(item.created_time).format('HH:mm DD/MM/YYYY')}</p>
                                                            <p className="mb-0 text-danger">Payment: {item.time_payment ? moment(item.time_payment).format('HH:mm DD/MM/YYYY') : ''}</p>
                                                        </td>
                                                        {/* <td>{item.time_payment ? moment(item.time_payment).format('YYYY/MM/DD HH:mm') : ''}</td> */}
                                                        <td>
                                                            <Input.TextArea value={item.note} />
                                                        </td>
                                                        <td>
                                                            {
                                                                item.status == 0 ?
                                                                    <Tooltip title="Xác nhận thanh toán">
                                                                        <span className="si si-pencil btn-payment-success" onClick={() => this.setState({ visibleForm: true }, () => {
                                                                            setTimeout(() => {
                                                                                if(this.form) this.form.setFieldsValue(item)
                                                                            }, 50)
                                                                        })}></span>
                                                                    </Tooltip> : null
                                                            }
                                                        </td>
                                                    </tr>
                                                }) : <tr><td colSpan={11}>Chưa có dữ liệu</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
