import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';
import libConfig from '../../../lib/config';

import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Alert} from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import { LoadSpinner } from '../../../widgets';
import { ListPackageComponent } from '../../../component';

export default class PLan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            totalData: 0
        };
        this.listPackageSystems = [];

        try{
            this.listPackageSystems = JSON.parse(JSON.stringify(this.props.service.packages));
        } catch(err){

        }

        this.total_payment = 0;
        this.limit = 10;
        this.page = 1;
    }
    componentWillMount = async () => {
        this.getListTransactions();
    }
    payment = async () => {
        let package_selected = this.listPackageSystems.filter((item) => item.checked);
        if (package_selected.length == 0) {
            this.props.notification({
                title: 'Chọn gói cước',
                content: 'Bạn phải chọn ít nhất 1 gói để tiếp tục!',
                type: "warning"
            })
            return;
        }
        let package_payment = {};
        let total_payment = 0;
        let result = true;
        package_selected.forEach((item) => {
            if(item.package_type == 3){
                if(item.num_message < item.min_message){
                    this.props.notification({
                        title: `Gói "${item.title}" không hợp lệ`,
                        content: `Min số lượng gửi >= ${item.min_message} tin`,
                        type: "warning"
                    })
                    result = false;
                    return
                }
            }
            package_payment[item.name] = {
                number_month: parseFloat(item.selected_month),
                package_vip: parseInt(item.package_vip),
                number_page: parseInt(item.number_page),
                num_message: parseInt(item.num_message),
                title: item.title,
                function: item.name
            }
            total_payment += item.total_amount;
        })
        let data = {
            data: package_payment,
            total: total_payment
        }

        if(!result) return;

        localStorage.setItem('payment', JSON.stringify(data));

        this.props.history.push('/plans/payment');
    }
    getListTransactions = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let query = `limit=${this.limit}&page=${this.page}`;
            // for (let [key, value] of Object.entries(this.state.valuesFilter)) {
            //     query += `&${key}=${value}`;
            // }
            let res = await fetchData({
                url: `api/v2/service/transaction?${query}`
            });

            this.setState({ listData: res.data, loading: false, totalData: res.total });
        } catch (err) {
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Danh sách gói cước</h3>
                            </div>
                            {/* <div className="p-20">
                            <Alert type="info" message={
                                <div>
                                    <p className="mb-15">Hiện tại phần mềm đang mở miễn phí tính năng <strong>VIP SPAM FANPAGE</strong>, Các bạn làm theo các bước sau để trải nghiệm miễn phí nhé: </p>
                                    <p className="mb-10"><strong>Bước 1:</strong> Bấm chọn gói <strong>VIP SPAM FANPAGE</strong> một tháng.</p>
                                    <p className="mb-10"><strong>Bước 2:</strong> Bấm thanh toán → Bấm tiếp lấy nội dung chuyển khoản (không cần chuyển khoản).</p>
                                    <p className="mb-10"><strong>Bước 3:</strong> Khi đã bấm xong các bước nhắn <a target="_blank" href={this.props.service.link_support}>vào đây</a> cho admin để kích hoạt là sử dụng nhé.</p>
                                </div>
                            } />
                            </div> */}
                            <div className="block-content">
                                <ListPackageComponent {...this.props} listPackageSystems={this.listPackageSystems}/>
                                <div className="form-group text-right">
                                    <button className="btn btn-m" onClick={this.payment}>Thanh toán</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Thanh toán</h3>
                            </div>
                            <div>
                                {
                                    this.limit == this.state.listData.length || this.page > 1 ? 
                                    <div className="block-header">
                                        <div></div>
                                        <Pagination
                                            total={this.state.totalData}
                                            pageSize={this.limit}
                                            current={this.page}
                                            onChange={(current) => {
                                                this.page = current;
                                                this.getListTransactions();
                                            }}
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="block-content">
                                <div className="table-responsive">
                                    <LoadSpinner show={this.state.loading} />
                                    <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                        <thead>
                                            <tr>
                                                {/* <th>#</th> */}
                                                <th>Mã</th>
                                                <th className="text-left">Gói</th>
                                                <th>Số tiền</th>
                                                <th>Ngày tạo</th>
                                                <th>Trạng thái</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                    return <tr key={item.code}>
                                                        {/* <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td> */}
                                                        <td>{item.code}</td>
                                                        <td className="text-left">
                                                            {
                                                                item.options ? item.options.map((option, i) => {
                                                                    return (<p className="m-0" key={i}>
                                                                        <strong> - {
                                                                            option.title
                                                                        } </strong> ({ option.number_page && !option.is_vip ? option.number_page+' nick / ' : ''}{option.number_month+' tháng'})
                                                                    </p>)
                                                                }) : null
                                                            }
                                                        </td>
                                                        <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} />đ</td>
                                                        <td>{moment(item.created_time).format('YYYY/MM/DD HH:mm')}</td>
                                                        <td>
                                                            {
                                                                item.status == 1 ?
                                                                    <span className="badge badge-success">Đã thanh toán</span>
                                                                    : <span className="badge badge-primary">Chờ thanh toán</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                }) : <tr>
                                                    <td colSpan={11}>Chưa có dữ liệu</td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
