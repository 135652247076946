import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../../lib/apis';
import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip} from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import { LoadSpinner } from '../../../../widgets';

export default class AdminWithDrawsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { data: [], total: 0},
            valuesFilter: {}
        };
        this.page = 1;
        this.limit = 20;
    }
    componentWillMount = async () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let query = `limit=${this.limit}&page=${this.page}`;
            for (let [key, value] of Object.entries(this.state.valuesFilter)) {
                query += `&${key}=${value}`;
            }
            let res = await fetchData({
                url: `api/v2/ow/affiliate/withdraw?${query}`
            });

            this.setState({ data: res, loading: false});
        } catch (err) {
            this.setState({ loading: false });
        }
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 300)
    }
    handleFilter = (e) => {
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter }, () => { this.getListData('reset') });
    }
    submitForm = async () => {
        const values = await this.formH.validateFields();

        this.setState({
            confirmLoading: true
        })
        try {
            let res = await fetchData({
                url: `api/v2/ow/affiliate/withdraw/${this.state.selectedAction._id}`,
                method: 'put',
                data: values
            });
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Xác nhận thanh toán',
                type: 'success'
            })
            this.formH.resetFields();
            this.getListData();
            this.setState({
                confirmLoading: false,
                visibleForm: false
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xác nhận thanh toán',
                type: 'error'
            })
            this.setState({
                confirmLoading: false
            })
        }
    }
    showForm = (e, item) => {
        e.preventDefault();
        this.setState({
            visibleForm: true,
            selectedAction: item
        })
    }
    handleCancelForm = () => {
        this.setState({
            visibleForm: false
        })
    }
    render() {
        return (
            <div className="content">
                <Modal
                    title={'Xác nhận thanh toán cho tài khoản affiliate'}
                    visible={this.state.visibleForm}
                    onOk={this.submitForm}
                    onCancel={this.handleCancelForm}
                    footer={[
                        <Button key="back" onClick={this.handleCancelForm}>
                        Đóng
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.confirmLoading} onClick={this.submitForm}>
                        Lưu
                        </Button>,
                    ]}
                    >
                    <Form
                        name="basic"
                        ref={(evt) => this.formH = evt}
                        initialValues={{
                            status: 1
                        }}
                    >
                        <Form.Item
                            name="status"
                            label="Trạng thái"
                        >
                            <Select>
                                <Select.Option value={1}>Đã thanh toán</Select.Option>
                                <Select.Option value={2}>hủy yêu cầu</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="note"
                            label="Ghi chú"
                        >
                            <Input.TextArea style={{minHeight: "160px"}}/>
                        </Form.Item>
                    </Form>
                </Modal>
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Người dùng affiliate</h3>
                            </div>
                            <div className="block-header">
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input placeholder="Tìm theo email" style={{ width: "250px", marginRight: "10px" }} onChange={this.handleFilterSearch} name="email" className="form-control"/>
                                    <select className="form-control" name="status" onChange={this.handleFilterSearch}>
                                        <option value="">Trạng thái</option>
                                        <option value={0}>Đang chờ duyệt</option>
                                        <option value={1}>Đã thanh toán</option>
                                        <option value={2}>Đơn bị hủy</option>
                                    </select>
                                    {/* <Button style={{ marginRight: "10px" }} type="primary" icon={<ReloadOutlined />} onClick={() => this.getListData()} /> */}
                                </div>
                                {
                                    this.state.data.total > 0 ?
                                    <Pagination
                                        total={this.state.data.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.page = current;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                    : null
                                }
                            </div>
                            <div className="block-content">
                                <div className="table-responsive">
                                    <LoadSpinner show={this.state.loading} />
                                    <table className="table table-striped table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>Thông tin cơ bản</th>
                                                <th>Số tiền</th>
                                                <th>Thông tin ck</th>
                                                <th>Ngày yêu cầu</th>
                                                <th>Trạng thái</th>
                                                <th className="text-center">Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.data.length >  0 ? this.state.data.data.map((item, i) => {
                                                    return <tr key={i}>
                                                        <td>
                                                            Email: {item.email}<br></br>
                                                            Số điện thoại: {item.phone} <br></br>
                                                        </td>
                                                        <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td>
                                                        <td>
                                                            <textarea className="form-control" disabled style={{maxWidth: "300px", margin: "auto"}}>{item.info_payment}</textarea>
                                                        </td>
                                                        <td>{moment(item.created_time).format('HH:mm DD/MM/YYYY')}</td>
                                                        <td>
                                                            {
                                                                item.status == 1 ?
                                                                <span className="badge badge-success">Đã duyệt</span>
                                                                : item.status == 2 ?
                                                                <span className="badge badge-danger">Bị từ chối</span>
                                                                : <span className="badge badge-primary">Chờ duyệt</span>
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                item.status == 0 ?
                                                                <Tooltip title="Xét duyệt">
                                                                    <a href="#" className="si si-pencil text-black" onClick={(e) => this.showForm(e, item)}></a>
                                                                </Tooltip> : null
                                                            }
                                                        </td>
                                                    </tr>
                                                }) : <tr><td className="text-center" colSpan={11}>Chưa có dữ liệu</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
