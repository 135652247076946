import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Switch } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import qs from 'qs';

export default class BotCommment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            services: [],
            totalSyncGroup: 0
        };
        this.page = 1;
        this.limit = 20;
    }
    componentWillMount = async () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let query = {
                page: this.page,
                limit: this.limit
            };
            if (this.formFilter) {
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let res = await fetchData({
                url: `api/v2/auto-comment/list?${qs.stringify(query)}`
            });

            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
        }
    }

    delete = async (option = {}) => {
        let _this = this;
        let { item, type } = option;
        let ids = [];
        if (type == 'delete_manny') {
            let items_checked = document.querySelectorAll('[name="check_item"]:checked');
            if (items_checked.length == 0) {
                _this.props.notification({
                    content: 'Bạn phải chọn ít nhất 1 đối tượng!',
                    title: 'Xóa dữ liệu',
                    type: 'error',
                    key: 'delete'
                })
                return
            }
            items_checked.forEach((item) => {
                ids.push(item.value);
            })
        } else {
            ids = [item._id];
        }
        Modal.confirm({
            title: 'Xóa dữ liệu',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v2/auto-comment/delete`,
                            method: 'delete',
                            data: {
                                ids: ids
                            }
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa dữ liệu',
                            type: 'success',
                            key: 'delete'
                        })
                        document.querySelector('[name="checkall"]').checked = false;
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Thao tác thất bại',
                            title: 'Xóa dữ liệu',
                            type: 'error',
                            key: 'delete'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    saveData = async (values) => {
        try {
            let ids = values['ids'].split('\n');
            if (!this.props.checkConnectExt()) throw ({ message: `Bạn chưa cài tiện ích hoặc version tiện ích đã cũ, vui lòng cài version lớn hơn hoặc bằng ${this.props.service.ver_ext || ''}` });
            this.setState({ loadingForm: true });
            let res_token = await window.extApp.request({ method: 'get_token' });
            console.log(res_token);
            ids = ids.map((item) => {
                return item.replace(/\D/g, '');
            })
            let data = [];
            let fields = 'id,name,picture';
            if (values['type'] == 2) fields += ',privacy';
            let type = {
                "Page": 1,
                "Group": 2,
                "User": 3,
            }
            console.log(values);
            while (ids.length > 0) {
                try {
                    let ids_check = ids.splice(0, 100);
                    let promise = [];
                    for (let i = 0; i < ids_check.length; i++) {
                        let id = ids_check[i];
                        promise.push(new Promise(async (resolve, reject) => {
                            try {
                                let response = await window.extApp.request({
                                    method: "send_fb",
                                    data: {
                                        url: `https://graph.facebook.com/v8.0/${id}?fields=${fields}&access_token=${res_token.data}`
                                    }
                                })
                                console.log(response);

                                response = response.data;

                                data.push({
                                    fb_id: response.id,
                                    name: response.name,
                                    type: values.type,
                                    picture: response.picture.data.url,
                                    privacy: response.privacy
                                })
                                resolve();
                            } catch (err) {
                                resolve();
                            }
                        }))
                    }
                    await Promise.all(promise);
                } catch (err) {

                }
            }

            let res = await fetchData({
                url: `api/v2/auto-comment/save`,
                method: 'post',
                data: { data: data }
            });
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công!',
                title: 'Cập nhập dữ liệu',
                type: 'success'
            })
            this.setState({ loadingForm: false });
        } catch (err) {
            console.log(err);
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập dữ liệu',
                type: 'error'
            })
        }
    }
    update = async (id, active) => {
        try {
            let res = await fetchData({
                url: `api/v2/auto-comment/${id}`,
                method: 'put',
                data: {
                    active: active ? 2 : 1
                }
            });
            let listData = this.state.listData;
            let index = listData.findIndex((item) => item._id == id);
            listData[index]['active'] = active ? 2 : 1;
            this.setState({listData: listData})
            this.props.notification({
                content: 'Thao tác thành công!',
                title: 'Kích hoạt',
                type: 'success',
                key: "cmt"
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Kích hoạt',
                type: 'error',
                key: "cmt"
            })
        }
    }
    syncGroup = async () => {
        try {
            this.setState({ loadingForm: true, totalSyncGroup: 0 });
            if (!this.props.checkConnectExt()) throw ({ message: `Bạn chưa cài tiện ích hoặc version tiện ích đã cũ, vui lòng cài version lớn hơn hoặc bằng ${this.props.service.ver_ext || ''}` });

            let res_token = await window.extApp.request({ method: 'get_token' });
            console.log(res_token);
            let access_token = res_token.data;
            let url = `https://graph.facebook.com/v8.0/me/groups?fields=id,name,picture,privacy&access_token=${access_token}&limit=100`;

            while (true) {
                let response = await window.extApp.request({
                    method: "send_fb",
                    data: {
                        url: url
                    }
                })
                response = response.data;
                let data = [];
                // response = await response.json();
                response.data.forEach((item) => {
                    data.push({
                        fb_id: item.id,
                        name: item.name,
                        type: 2,
                        picture: item.picture.data.url,
                        privacy: item.privacy
                    })
                })
                if (data.length > 0) {
                    let res = await fetchData({
                        url: `api/v2/auto-comment/save`,
                        method: 'post',
                        data: { data: data }
                    });
                    this.setState({ totalSyncGroup: this.state.totalSyncGroup + data.length })
                }
                if (response.paging.next) {
                    url = response.paging.next;
                } else {
                    break;
                }
                await this.props.sleep(2000);
            }
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công!',
                title: 'Cập nhập group',
                type: 'success'
            })
            this.setState({ loadingForm: false });
        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập group',
                type: 'error'
            })
        }
    }
    updateMany = ({ data, title}) => {
        let _this = this;
        let ids = [];
        data = data ? data : {};
        let items_checked = document.querySelectorAll('[name="check_item"]:checked');
        if (items_checked.length == 0) {
            _this.props.notification({
                content: 'Bạn phải chọn ít nhất 1 đối tượng!',
                title: title,
                type: 'error',
                key: 'update'
            })
            return
        }
        items_checked.forEach((item) => {
            ids.push(item.value);
        })
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v2/auto-comment/update`,
                            method: 'post',
                            data: {
                                ids: ids,
                                ...data
                            }
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập dữ liệu',
                            type: 'success',
                            key: 'update'
                        })
                        document.querySelector('[name="checkall"]').checked = false;
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Thao tác thất bại',
                            title: 'Cập nhập dữ liệu',
                            type: 'error',
                            key: 'update'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    render() {
        return (
            <div className="content">
                <Modal
                    title={'Cập nhập dữ liệu'}
                    visible={this.state.visibleForm}
                    onCancel={() => this.setState({ visibleForm: false })}
                    footer={null}
                >
                    <Form
                        onFinish={this.saveData}
                        initialValues={{
                            type: ""
                        }}
                    >
                        <Form.Item label="Danh sách profile, fanpage, group"
                            name="ids"
                            rules={[{ required: true, message: 'Bạn phải nhập ít nhất 1 id' }]}
                        >
                            <Input.TextArea style={{ minHeight: "100px" }} />
                        </Form.Item>
                        <Form.Item label="Phân loại" name="type">
                            <Select>
                                <Select.Option value=""> Chọn loại</Select.Option>
                                <Select.Option value={1}>Fanpage</Select.Option>
                                <Select.Option value={2}>Group</Select.Option>
                                <Select.Option value={3}>Profile</Select.Option>
                            </Select>
                        </Form.Item>
                        <div className="alert alert-primary pt-2 pb-2">Ghi chú: Mỗi id 1 dòng</div>
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" loading={this.state.loadingForm}>Cập nhập</Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title={'Cập nhập group'}
                    visible={this.state.visibleFormGroup}
                    onCancel={() => this.setState({ visibleFormGroup: false })}
                    footer={null}
                >
                    <div>
                        <p>Kết quả: {this.state.totalSyncGroup}</p>
                        <Button htmlType="button" type="primary" loading={this.state.loadingForm} onClick={this.syncGroup}>Cập nhập</Button>
                    </div>
                </Modal>
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Auto comment</h3>
                            </div>
                            <div className="block-content">
                                <div className="d-sm-flex align-items-center justify-content-between mb-10">
                                    <div className="d-flex mb-10">
                                        <button className={`btn btn-primary btn-sm d-flex`}>
                                            Tất cả: {this.state.total}
                                        </button>
                                        <Tooltip title="Xóa">
                                            <button type="button" className={`btn btn-danger btn-sm d-flex align-items-center ml-5`} onClick={() => this.delete({ type: 'delete_manny' })}>
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Làm mới">
                                            <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={this.getListData}>
                                                <i className="fa fa-refresh"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Cập nhập group">
                                            <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.setState({ visibleFormGroup: true })}>
                                                <i className="fa fa-users"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Kích hoạt">
                                            <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.updateMany({ data: { active: 2}, title: "Kích hoạt"})}>
                                                <i className="fa fa-check"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Bỏ kích hoạt">
                                            <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.updateMany({ data: { active: 1}, title: 'Bỏ kích hoạt'})}>
                                                <i className="fa fa-times-circle"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Thêm mới">
                                            <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.setState({ visibleForm: true })}>
                                                Thêm mới
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className={`${this.props.loading ? 'data-loading' : ''}`}>
                                        <Pagination
                                            total={this.state.total}
                                            defaultCurrent={1}
                                            showSizeChanger={true}
                                            pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                            pageSize={this.limit}
                                            current={this.page}
                                            onChange={(current) => {
                                                this.page = current;
                                                this.getListData();
                                            }}
                                            onShowSizeChange={(current, size) => {
                                                this.limit = size;
                                                this.getListData();
                                            }}
                                        // simple
                                        />
                                    </div>
                                </div>
                                <Form
                                    initialValues={{
                                        active: "",
                                        type: ""
                                    }}
                                    onChange={(e) => {
                                        if (e.target.nodeName == 'INPUT') {
                                            if (this.timeout) clearTimeout(this.timeout);
                                            this.timeout = setTimeout(() => {
                                                this.getListData();
                                            }, 300)
                                        } else {
                                            this.getListData();
                                        }
                                    }}
                                    ref={(evt) => this.formFilter = evt}
                                >
                                    <div className="row">
                                        <div className="col-md-2 col-lg-2 pl-15 pr-5">
                                            <Form.Item name="name">
                                                <Input placeholder="Từ khóa" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                            <Form.Item name="active">
                                                <Select onChange={(e) => {
                                                    this.getListData();
                                                }}>
                                                    <Select.Option value="">Trạng thái</Select.Option>
                                                    <Select.Option value={1}>Chưa kích hoạt</Select.Option>
                                                    <Select.Option value={2}>Kích hoạt</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                            <Form.Item name="type">
                                                <Select onChange={(e) => {
                                                    this.getListData();
                                                }}>
                                                    <Select.Option value="">Loại</Select.Option>
                                                    <Select.Option value={1}>Fanpage</Select.Option>
                                                    <Select.Option value={2}>Group</Select.Option>
                                                    <Select.Option value={3}>Profile</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                                <Spin spinning={this.state.loading} tip="loading..">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px" }}>
                                                        <label className="css-control css-control-primary css-checkbox p-0">
                                                            <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}
                                                            />
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </th>
                                                    <th style={{ width: "50px" }}>#</th>
                                                    <th>Ảnh</th>
                                                    <th>Id</th>
                                                    <th>Tên</th>
                                                    <th>Loại</th>
                                                    <th className="text-right">Kích hoạt</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                        return <tr key={item._id}>
                                                            <td>
                                                                <label className="css-control css-control-primary css-checkbox p-0">
                                                                    <input name="check_item" type="checkbox" className="css-control-input checkbox" value={item._id}
                                                                        onChange={this.props.handleChecked}
                                                                    />
                                                                    <span className="css-control-indicator"></span>
                                                                </label>
                                                            </td>
                                                            <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                            <td>
                                                                <img src={item.picture} style={{ width: "40px" }} />
                                                            </td>
                                                            <td>{item.fb_id}</td>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                {
                                                                    item.type == 1 ? "fanpage" : item.type == 2 ? "group" : item.type == 3 ? "profile" : ""
                                                                }
                                                            </td>
                                                            <td className="text-right">
                                                                <Switch checked={item.active == 2} onChange={(e) => this.update(item._id, e)} />
                                                            </td>
                                                        </tr>
                                                    }) : <tr><td colSpan={11} className="text-center">Chưa có dữ liệu</td></tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
