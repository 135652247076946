import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';

export default class PLanPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            get_payment: null
        };
        this.payment = {};
        try {
            let payment = JSON.parse(localStorage.getItem('payment'));
            if (!payment) throw ('error');
            this.payment = payment;
        } catch (err) {
            this.props.history.push('/plans');
        }
    }
    componentDidMount = () => {

    }
    componentWillUnmount = () => {
        localStorage.removeItem('payment');
    }
    getPayment = async () => {
        try {
            let response = await fetchData({
                url: 'api/v2/service/transaction',
                method: 'post',
                data: {
                    package: JSON.stringify(this.payment.data)
                }
            })
            this.setState({
                get_payment: response.data
            })
            this.props.notification({
                title: 'Lấy mã thanh toán',
                content: 'Lấy mã thành công',
                type: "success"
            })
            localStorage.removeItem('payment');
        } catch (err) {
            this.props.notification({
                title: 'Lấy mã thanh toán',
                content: 'Lấy mã thất bại',
                type: "error"
            })
        }
    }
    render() {
        let package_detail = [];
        let data = this.payment && this.payment.data ? this.payment.data : {};
        if (data.spam_vip_fanpage) {
            let number_month = data.spam_vip_fanpage.number_month < 1 ? `${Math.floor(data.spam_vip_fanpage.number_month * 30)} ngày` : `${data.spam_vip_fanpage.number_month} tháng`;
            package_detail.push(<span key={'vip_fp'}>Vip spam fanpage: {number_month}</span>)
        }
        if (data.spam_profile) {
            let number_month = data.spam_profile.number_month < 1 ? `${data.spam_profile.number_month * 30} ngày` : `${data.spam_profile.number_month} tháng`;
            if (data.spam_profile.package_vip == 2) {
                package_detail.push(<p className="m-0" key={'vip_profile'}>
                    <span>Vip spam cá nhân: {number_month}</span><br></br>
                </p>)
            } else {
                package_detail.push(<p className="m-0" key={'profile'}>
                    <span>Spam cá nhân: {data.spam_profile.number_page} nick / {number_month}</span>
                </p>)
            }
        }
        if (data.spam_to_fanpage) {
            let number_month = data.spam_to_fanpage.number_month < 1 ? `${data.spam_to_fanpage.number_month * 30} ngày` : `${data.spam_to_fanpage.number_month} tháng`;
            package_detail.push(<p className="m-0" key={'profile'}>
                <span>{data.spam_to_fanpage.title}: {data.spam_to_fanpage.num_message} tin nhắn / {number_month}</span>
            </p>)
        }
        if (data.botcomment) {
            let number_month = data.botcomment.number_month < 1 ? `${data.botcomment.number_month * 30} ngày` : `${data.botcomment.number_month} tháng`;
            package_detail.push(<p className="m-0" key={'profile'}>
                <span>{data.botcomment.title}: {number_month}</span>
            </p>)
        }
        return (
            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <nav className="breadcrumb push" style={{ background: "#fff", padding: "14px 20px" }}>
                            <Link to="/plans" className="breadcrumb-item">
                                Gói cước
                            </Link>
                            <span className="breadcrumb-item active">Thanh toán</span>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Thông tin gói cước</h3>
                            </div>
                            <div className="block-content detail-payment pt-5">
                                <div className="mb-10">
                                    <h5 className="mb-5"><span>Chi tiết</span></h5>
                                    <div style={{ background: "#f6f7f9", padding: "15px 20px" }}>
                                        {package_detail}
                                    </div>
                                </div>
                                <div className="mb-10">
                                    <h5 className="mb-5"><span>Tổng tiền</span></h5>
                                    <div style={{ background: "#f6f7f9", padding: "15px 20px" }}>
                                        <h4 className="mb-0 text-primary"><NumberFormat value={this.payment.total} displayType={'text'} thousandSeparator={true} />đ</h4>
                                    </div>
                                </div>
                                <div className="mb-10">
                                    <h5 className="mb-5"><span>Thanh toán</span></h5>
                                    <div>
                                        <div className="money-wallet">
                                            <p className="">
                                                <strong>Bước 1</strong>: Đăng nhập vào Internet banking ( ngân hàng điện tử )
                                                        </p>
                                            <p className="">
                                                <strong>Bước 2</strong>: Tạo giao dịch chuyển tiền tới một trong các tài khoản sau
                                                        </p>
                                            <div className="block-header-default block-rounded p-3 mb-3">
                                                {
                                                    this.props.service.banking ?
                                                        this.props.service.banking.map((item, i) => {
                                                            item = item.split('|');
                                                            return <p key={i}>
                                                                Ngân hàng: {item[2] || ''}<br></br>
                                                                Chủ TK: {item[1] || ''}<br></br>
                                                                STK: {item[0] || ''}<br></br>
                                                            </p>
                                                        })
                                                        : null
                                                }
                                            </div>
                                            <p>
                                                <strong>Bước 3</strong>: Nhập nội dung chuyển khoản chính xác như sau (click nút dưới để lấy)
                                                        </p>
                                            {
                                                this.state.get_payment ?
                                                    <div className="show-order">
                                                        <h3 className="block-title mb-2">Thông tin chi tiết thanh toán</h3>
                                                        <p className="mb-2"><strong>- Nội dung chuyển khoản là:</strong> <span className="text-danger">{this.state.get_payment.code}</span></p>
                                                        <p><strong>- Số tiền cần thanh toán là:</strong> <span className="text-danger"><NumberFormat value={this.state.get_payment.amount} displayType={'text'} thousandSeparator={true} />đ</span></p>
                                                        <div className="block-header-default block-rounded p-3">
                                                            <p className="mb-0 text-danger">Chú ý:</p>
                                                            <p className="mb-0 text-danger">**Vui lòng nhập chính xác nội dung chuyển khoản như trên.</p>
                                                            <p className="mb-0">Sau khi nhận được thông báo từ ngân hàng và gói cước được xác thực, hệ thống sẽ tự động kích hoạt gói cước sau 2-5 phút.Trong trường hợp bạn điền sai thông tin hoặc có bất kỳ sự cố gì khiến hệ thống không thể tự động kích hoạt,vui lòng Liên hệ <a href={this.props.service.link_support} target="_blank">Fanpage</a> để được hỗ trợ sớm nhất</p>
                                                        </div>
                                                    </div> : <button className="btn btn-m mb-3" onClick={this.getPayment}>Lấy nội dung chuyển khoản</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
