import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import io from 'socket.io-client';

import { AccountMetaData, setCookie, dimens, getPicture, versionCompare} from '../../config/app';
import { fetchData, requestApiFb } from '../../lib/apis';

import { HeaderApp, SidebarApp } from '../../component/app';
import {
    AccountView, PLanView, PLanPaymentView, AdminTransactionView, GuidesView, AffiliateView, ResultView, AffiliateTransactionView, AffiliateWithDrawsView,
    AdminAffiliateView, AdminAffiliateWithDrawsView, ScanFanpageView, BotCommentView, AdminUserView, AdminServiceView
} from '../app';

import extApp from '../../lib/conector.js';

export default class MainlayoutView extends Component {
    constructor(props) {
        super(props);
        let user = null;
        if (!AccountMetaData.checkIssetLogin()) {
            AccountMetaData.logIn();
        } else {
            user = dimens['user'];
        }
        this.state = {
            user: user,
            loadingMain: false,
            service: null
        };
        try{
            if (window.Notification.permission !== 'granted') {
                window.Notification.requestPermission();
            }
        } catch(err){
            
        }
    }
    componentWillMount = () => {
        if(AccountMetaData.checkIssetLogin()){
            this.getDetail();
            this.getInfo();
        }

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_FB_ID,
                cookie: true,
                xfbml: true,
                version: 'v8.0'
            });
        }.bind(this);

        // (function (d, s, id) {
        //     var js, fjs = d.getElementsByTagName(s)[0];
        //     if (d.getElementById(id)) return;
        //     js = d.createElement(s); js.id = id;
        //     js.src = "//connect.facebook.net/en_US/sdk.js";
        //     fjs.parentNode.insertBefore(js, fjs);
        // }(document, 'script', 'facebook-jssdk'));

    }
    componentDidMount = async () => {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        try {
            await extApp.init();
            window.extApp = extApp;
        } catch (err) {
            console.log(err);
        }
    }
    getInfo = async () => {
        try {
            let response = await requestApiFb({
                url: '/me?fields=about,name,picture{url},email',
                method: 'get'
            });
            let user = this.state.user;
            user['display_name'] = response.name;
            this.setState({
                user: user
            })
        } catch (err) {
            AccountMetaData.logOut();
        }
    }

    getDetail = async () => {
        try {
            let response = await fetchData({
                url: 'api/v2/me',
                method: 'get'
            });
            if(response.token) setCookie('token', response.token, 3);

            this.setState({
                user: response.data,
                service: response.service
            })
        } catch (err) {
            console.log(err);
            AccountMetaData.logOut();
        }
    }
    handleLoading = (bol) => {
        this.setState({
            loadingMain: bol
        })
    }
    renderTitle = () => {
        let title = 'Tiện ích gửi tin nhắn hàng loại';
        switch (this.props.location.pathname) {
            case "/accounts":
                title = 'Tài khoản'
                break;
            case "/plans":
                title = 'Gói cước'
                break;
            default:
                break
        }
        return title;
    }
    handleChecked = (e) => {
        try {
            let table = e.target.closest('table');
            let items = table.querySelectorAll('[name="check_item"]');
            let num_checked = 0;
            items.forEach((item) => {
                if (item.checked) num_checked++;
            })
            if (e.target.name == 'checkall') {
                if (items.length == num_checked) {
                    items.forEach((item) => {
                        item.checked = false;
                    })
                    num_checked = 0;
                } else {
                    items.forEach((item) => {
                        item.checked = true;
                    })
                    num_checked = items.length;
                }
            }
            if (items.length == num_checked) {
                table.querySelector('[name="checkall"]').checked = true;
            } else {
                table.querySelector('[name="checkall"]').checked = false;
            }
        } catch (err) {
            console.log(err);
        }
    }
    checkConnectExt = () => {
        try{
            if (window.extApp && window.extApp.isConnected) {
                if(!window.extApp.manifest || versionCompare(this.state.service.ver_ext, window.extApp.manifest.version) == 1){
                    throw(true);
                } else{
                    return true;
                }
            } else {
                throw(true);
            }
        } catch(err){
            return false;
        }
    }
    sleep = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(ms);
            }, ms)
        });
    }
    render() {
        // if(!this.state.loadingMain) return <LoadingComponent />;
        if(!this.state.user || !this.state.service) return null;
        var props_m = {
            ...this.props,
            user: this.state.user,
            getPicture: getPicture,
            service: this.state.service,
            handleChecked: this.handleChecked,
            checkConnectExt: this.checkConnectExt,
            sleep: this.sleep
        }
        return (
            <div id="page-container" className="sidebar-o sidebar-inverse enable-page-overlay side-scroll page-header-fixed main-content-narrow side-trans-enabled">
                <Helmet>
                    <title>
                        {this.renderTitle()}
                    </title>
                </Helmet>
                <HeaderApp {...props_m} />
                <SidebarApp {...props_m} />

                <div id="fb-root"></div>
                <div className="fb-customerchat" attribution="setup_tool" page_id={process.env.REACT_APP_FANPAGE_SUPPORT_ID}></div>

                <main id="main-container">
                    <Switch>
                        <Route
                            exact
                            path='/accounts'
                            render={props => <AccountView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/plans'
                            render={props => <PLanView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/plans/payment'
                            render={props => <PLanPaymentView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/guides'
                            render={props => <GuidesView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/affiliate'
                            render={props => <AffiliateView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/affiliate/withdraws'
                            render={props => <AffiliateWithDrawsView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/affiliate/transaction'
                            render={props => <AffiliateTransactionView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/auto-comment'
                            render={props => <BotCommentView {...props_m} {...props} />}
                        />
                        
                        <Route
                            exact
                            path='/admin/transaction'
                            render={props => <AdminTransactionView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/admin/affiliate/user'
                            render={props => <AdminAffiliateView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/admin/affiliate/withdraws'
                            render={props => <AdminAffiliateWithDrawsView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/admin/user'
                            render={props => <AdminUserView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/service'
                            render={props => <AdminServiceView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/'
                            render={props => <AccountView {...props_m} {...props} />}
                        />
                        <Route
                            path=''
                            render={props => <ResultView {...props_m} {...props} />}
                        />
                    </Switch>
                </main>
            </div>
        )
    }
}
