const config = {
    type_account: {
        1: "instagram",
        2: "facebook",
        3: "shopee",
        4: "vchat",
        5: 'fanpage FB',
        6: 'Chợ tốt',
        7: 'Zalo'
    },
    type_account_social: {
        1: {
            name: 'instagram',
            icon: require('../img/insta.svg')
        },
        2: {
            name: 'facebook',
            icon: require('../img/fb.svg')
        },
        3: {
            name: 'shopee',
            icon: require('../img/shopee.png')
        },
        4: {
            name: 'vchat',
            icon: require('../img/vchat.ico')
        },
        5: {
            name: 'fanpage FB',
            icon: require('../img/fb.svg')
        },
        6: {
            name: 'Chợ tốt',
            icon: require('../img/chotot.png')
        },
        7: {
            name: 'Zalo'
        },
        99: {
            name: 'other'
        }
    },
    arr_type_account: [
        {
            type: 1,
            name: 'Instagram'
        },
        {
            type: 2,
            name: 'Facebook cá nhân'
        },
        {
            type: 3,
            name: 'Shoppee'
        },
        {
            type: 4,
            name: 'Vchat'
        },
        {
            type: 5,
            name: 'Fanpage facebook'
        },
        {
            type: 6,
            name: 'Chợ tốt'
        }
    ],
    block_send: {
        block_insta_direct: process.env.REACT_APP_BLOCK_INSTA_DIRECT ? process.env.REACT_APP_BLOCK_INSTA_DIRECT : 0,
        block_insta_cmt: process.env.REACT_APP_BLOCK_INSTA_CMT ? process.env.REACT_APP_BLOCK_INSTA_CMT : 0,
        block_insta_direct_new: process.env.REACT_APP_BLOCK_INSTA_DIRECT_NEW ? process.env.REACT_APP_BLOCK_INSTA_DIRECT_NEW : 0,
    },
    getAccType: {
        "instagram" : 1,
        "facebook" : 2,
        "shopee" : 3,
        "vchat" : 4,
        'fanpage' : 5,
        "chotot" : 6,
        "zalo": 7
    },
    menuDashboard: [
        {
            id: 0,
            menu_name: 'Hộp thư đến',
            parent_id: 0,
            link: '/chat',
            icon: require('../img/icon-chat.svg'),
            target: '_blank'
        },
        {
            id: 1,
            menu_name: 'Kênh bán hàng',
            parent_id: 0,
            menu_child: [
                {
                    id: 3,
                    menu_name: 'Fanpage',
                    parent_id: 1,
                    link: '/fanpage',
                    icon: require('../img/fb.svg'),
                    alias: 'fanpage'
                },
                // {
                //     id: 4,
                //     menu_name: 'Facebook cá nhân',
                //     parent_id: 1,
                //     link: '/facebook',
                //     icon: require('../img/fb.svg'),
                //     alias: 'facebook'
                // },
                {
                    id: 5,
                    menu_name: 'Instagram',
                    parent_id: 1,
                    link: '/instagram',
                    icon: require('../img/insta.svg'),
                    alias: 'instagram'
                },
                {
                    id: 6,
                    menu_name: 'Shopee',
                    parent_id: 1,
                    link: '/shopee',
                    icon: require('../img/shopee.png'),
                    alias: 'shopee',
                },
                // {
                //     id: 7,
                //     link: '/zalo',
                //     menu_name: 'Zalo',
                //     parent_id: 1,
                //     icon: require('../img/zalo.png'),
                //     alias: 'zalo'
                // },
                {
                    id: 9,
                    menu_name: 'Chợ tốt',
                    parent_id: 1,
                    link: '/chotot',
                    alias: 'chotot'
                },
                {
                    id: 10,
                    menu_name: 'Vchat',
                    parent_id: 1,
                    link: '/vchat',
                    alias: 'vchat'
                }
            ]
        },
        {
            id: 2,
            menu_name: 'Hệ thống',
            parent_id: 0,
            menu_child: [
                {
                    id: 11,
                    menu_name: 'Phân quyền',
                    parent_id: 2,
                    link:  '/role'
                },
                {
                    id: 12,
                    menu_name: 'Tool hỗ trợ chat Instagram',
                    parent_id: 2,
                    link:  '//drive.google.com/open?id=1UHP5hvuvJ6-FkM85gDZHnSNukfShvaNK',
                    target: '_blank',
                    icon_fa: 'fal fa-download'
                },
                {
                    id: 13,
                    menu_name: 'Đăng xuất',
                    parent_id: 2,
                    action: 'logout',
                    icon_fa: 'fal fa-sign-out'
                }
            ]
        }

    ],
    statusAcc : (status, acc_type) => {
        let log = 'Hoạt động';
        let bg = "#4caf50";

        if(acc_type != 5){
            switch(status){
                case 0 : 
                    log = 'Đang chờ';
                    bg = "#FFEB3B"
                    break;
                case 1 : 
                    log = 'Hoạt động';
                    bg = "#4caf50"
                    break;
                case 2 : 
                    log = 'Sai mật khẩu';
                    bg = "red"
                    break;
                case 3 : 
                    log = 'Throtled';
                    bg = "red"
                    break;
                case 4 : 
                    log = 'Challenge required';
                    bg = "red"
                    break;
                case 5 : 
                    log = 'Sai cookie';
                    bg = "red"
                    break;
                case 6 : 
                    log = 'User has logged out';
                    bg = "red"
                    break;
                case 7 : 
                    log = 'Feedback required';
                    bg = "red"
                    break;
                case 8 : 
                    log = 'Đã xảy ra lỗi';
                    bg = "red"
                    break;
                default:
                    log = 'Lỗi không xác định';
                    bg = "red";
            }
        } else if(acc_type == 5){
            switch(status){
                case 0 : 
                    log = 'Đang chờ';
                    bg = "#FFEB3B"
                    break;
                case 1 : 
                    log = 'Hoạt động';
                    bg = "#4caf50"
                    break;
                case 2 : 
                    log = 'Token không hoạt động, vui lòng cập nhật lại !';
                    bg = "red"
                    break;
                case 3 : 
                    log = 'Bạn chưa cấp đủ quyền cho phần mềm thao tác với Fanpage, vui lòng cập nhật lại !';
                    bg = "red"
                    break;
                default:
                    log = 'Lỗi không xác định';
                    bg = "red";
            }
        }
        return {
            status: log,
            color: bg
        };
    },
    role_staff: [
        {
            "acc_type" : 1,
            "inbox" : false,
            "comment" : false,
            "name": "Instagram"
        },
        {
            "acc_type" : 5,
            "inbox" : false,
            "comment" : false,
            "name": "Fanpage"
        }
    ],
    link_support_ext: process.env.REACT_APP_LINK_EXT_SUPPORT,
    warehouse: {
        1 : "Kho mặc định"
    },
    listLevelAffiliate : [
        {
            "name": "SILVER",
            "value": [
                "+ 0-50 khách hàng.",
                "+ 45% cho khách hàng mới.",
                "+ 20% trọn đời cho mỗi đơn hàng gia hạn."
            ]
        },
        {
            "name": "GOLD",
            "value": [
                "+ 50-100 khách hàng.",
                "+ 50% cho khách hàng mới.",
                "+ 25% trọn đời cho mỗi đơn hàng gia hạn."
            ]
        },
        {
            "name": "Premium",
            "value": [
                "+ 100-200 khách hàng.",
                "+ 50% cho khách hàng mới.",
                "+ 30% trọn đời cho mỗi đơn hàng gia hạn."
            ]
        },
        {
            "name": "Diamond",
            "value": [
                "+ Từ 200 khách hàng trở lên.",
                "+ 55% cho khách hàng mới.",
                "+ 35% trọn đời cho mỗi đơn hàng gia hạn."
            ]
        }
    ]
};
export default config;