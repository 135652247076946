import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { message, Spin, notification, Modal} from 'antd'

import LoginApp from './views/app/login';
import Policy from './views/policy';
import { MainlayoutView } from './views';

class AppComponent extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount = () => {
      // if(!localStorage.getItem("read_noti_m") && checkIssetLogin()){
      //   setTimeout(() => {
      //     this.notificationMain();
      //   }, 1000)
      // }
    }
    notificationMain = () => {
      Modal.info({
        title: 'Thông báo',
        className: 'modal-noti',
        content: (
          <div className="content">
            <p>Hệ thống mới nâng cấp, người dùng dịch vụ <strong>Fanpage</strong> vui lòng cập nhập lại page để tiếp tục sử dụng.</p>
            <p style={{marginBottom: "0px"}}>Xin cảm ơn!</p>
          </div>
        ),
        onOk() {
          localStorage.setItem("read_noti_m", 1);
        },
      });
    }

    notification = (options) => {
      let { type, title, content, key, duration} = options;
      notification[type]({
        message: title,
        description: content,
        key: key,
        duration: duration ? duration : 5
      })
    }
    message = (options) => {
      let { type, key, content, duration} = options;
      message[type]({
        content: content,
        key: key,
        duration: duration
      })
    }

    render () {
      return (
        <Router>
            <Switch>
                <Route exact path="/login" render={props => <LoginApp {...props} notification={this.notification} message={this.message}/>}/>
                <Route
                  exact
                  path='/policy'
                  render={props => <Policy {...props}/>}
                />
                <Route
                  path='/'
                  render={ props => <MainlayoutView {...props} notification={this.notification} message={this.message}/> }
                />
            </Switch>
        </Router>
      )
    }
}
export default AppComponent;
