import React, { Component } from 'react';
import Header from '../widgets/header2';
import { Helmet } from 'react-helmet'

export default class Polyci extends Component{
  constructor(props){
    super(props);
  }
  componentWillMount = () => {
      
  }
  componentDidMount = () => {

  }
  render(){
    return(
       <div id="app-container">
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>
        <Header />
        <div className="content-container" style={{overflow: 'auto'}}>
            <div className="privacy-container">
                <h1>Privacy Policy</h1>
                <br />
                <p>Cake Commerce ("Company") built the Vnpchat ("Product") app as a Commercial app. This SERVICE is provided by Cake Commerce and is intended for use as is.</p>
                <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of data if anyone decided to use our Service.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The data that we collect is used for providing and improving the Service. We will not use or share your data with anyone except as described in this Privacy Policy.</p>
                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Vnpchat unless otherwise defined in this Privacy Policy.</p>
                <br />
                <h3>Data Collection and Use</h3>
                <br />
                <p>In order to function, the app will collect your name, email address, your Facebook Page list and its data which include public data such as comments, posts, and private messages. The information that we request is will be retained by us and used as described in this privacy policy.</p>
                <p>Your data is stored on our server as long as your subscription is active. When an active subscription is no longer available. We have the right to remove your data automatically from our database after 60 days. In case you want to stop using our service and remove all data completely, please let us know. We will fulfill your request with 24 hours.</p>
                <p>For a better experience and statistical, the app does use third party services that may collect information used to identify you. These third party services may collect information such as your device Internet Protocol (“IP”) address, device name, operating system version, the time and date of your use of our app, and other statistics.</p>
                <p>Link to privacy policy of third party service providers used by the app</p>
                <ul>
                    <li>
                        <p><a href="https://analytics.google.com" target="_blank">Google Analytics</a> <a href="https://policies.google.com/privacy" target="_blank">(Privacy)</a></p>
                    </li>
                </ul>
                <br />
                <h3>Facebook App Permissions</h3>
                <br />
                <p>The app will need following permissions from your Facebook account to function normally. You will be prompted to ask for these permissions upon first login to our app. The app will not work correctly without any required permission</p>
                <ul>
                    <li>
                        <p>manage_pages*: to retrieve the list and access token of Pages that you administrates</p>
                    </li>
                    <li>
                        <p>publish_pages*: to reply comment, like comment on behalf of Pages that you manage</p>
                    </li>
                    <li>
                        <p>read_page_mailboxes*, pages_messaging*: to read, receive and reply to Pages' messages</p>
                    </li>
                    <li>
                        <p>business_management (optional): to manage your business assets</p>
                    </li>
                    <li>
                        <p>ads_read (optional): to manage your ads</p>
                    </li>
                </ul>
                <br />
                <h3>Cookies</h3>
                <br />
                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your br /owser from the websites that you visit and are stored on your device's internal memory.</p>
                <p>The app uses cookies to validate your identity after you logged into your account. Beside, the app may use third party code and libr /aries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you will not be able to login into your account.</p>
                <br />
                <h3>Security</h3>
                <br />
                <p>We value your trust in providing us your data, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <br />
                <h3>Links to Other Sites</h3>
                <br />
                <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                <br />
                <h3>Children’s Privacy</h3>
                <br />
                <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                <br />
                <h3>Changes to This Privacy Policy</h3>
                <br />
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
                <br />
                <h3>Contact Us</h3>
                <br />
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
                <br />
            </div>
        </div>
      </div>
    )
  }
}
