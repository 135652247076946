// import {AccountMetaData} from  '../config/settings';
import { dimens, AccountMetaData } from  '../config/app';

export const apis = {
    // host: 'http://210.245.80.4:3009/api/v1',
    host: process.env.REACT_APP_API_URL+'/api/v1',
    api_key : process.env.REACT_APP_API_CHOTSALE_KEY,
    post_mes: {
        inbox: process.env.REACT_APP_API_INSTAGRAM_SV+'/InboxController.php',
        reply: process.env.REACT_APP_API_INSTAGRAM_SV+'/ReplyController.php',
        sendNew: process.env.REACT_APP_API_INSTAGRAM_SV+'/InboxNewUserController.php',
        MarkSeen: process.env.REACT_APP_API_INSTAGRAM_SV+'/MarkSeen.php',
        markSeenAll: process.env.REACT_APP_API_INSTAGRAM_SV+'/MarkSeenAll.php',
        DeleteDirect: process.env.REACT_APP_API_INSTAGRAM_SV+'/DeleteDirect.php',
        DeleteComment: process.env.REACT_APP_API_INSTAGRAM_SV+'/DeleteComment.php',
        ConvertCode: process.env.REACT_APP_API_INSTAGRAM_SV+'/ConvertCode.php',
    }
}

export function requestApi(data = {}){
    data['headers'] = data['headers'] ? data['headers'] : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : apis.api_key,
        'withCredentials': true
    };
    data['url'] = apis.host+''+data['url'];
    if(data['token']){
        data['headers']['Authorization'] = `JWT ${data['token']}`
    }
    let options = {
        method: data.method ? data.method : 'GET',
        headers: data.headers
    };
    if(options.method.toLocaleLowerCase() != 'get'){
        options['body'] = typeof data.data == 'object' ? JSON.stringify(data.data) : data.data;
    }
    return new Promise((resolve, reject) => {
        fetch(data['url'], options)
        .then(response =>  response.json())
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        })
    })
}
export function requestApi2(data = {}){
    data['headers'] = data['headers'] ? data['headers'] : {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    let options = {
        method: data.method ? data.method : 'GET',
        // headers: data.headers
    };
    if(options.method.toLocaleLowerCase() == 'post'){
        options['body'] = data.data;
    }
    return new Promise((resolve, reject) => {
        fetch(data['url'], options)
        .then(response =>  {
            return response.text().then(function(text) {
                return text ? JSON.parse(text) : {
                    status: 404,
                    message: 'Đã có lỗi xảy ra, vui lòng thử lại sau!'
                }
            })
        })
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        })
    })
}

export function fetchData(data = {}){
    let url = `${process.env.REACT_APP_API_URL}/${data['url']}`;
    let options = {
        method: data.method ? data.method : 'GET'
    };
    if(options.method.toLocaleLowerCase() != 'get'){
        options['body'] = data.data;
    }
    if(data['type_api'] == 1){
        url = `${process.env.REACT_APP_API_FVI}/${data['url']}`;
    } else if(data['type_api'] == 2){
        url = `${process.env.REACT_APP_API_INSTAGRAM_SV}/${data['url']}`;
    } else if(data['type_api'] == 0){
        url = data['url'];
    }
    if(data['type_api'] != 2){
        options['headers'] = {
            'Authorization' : AccountMetaData.getToken()
        }
        if(data.type_post != 'upload_image'){
            options['headers']['Content-Type'] = 'application/json';
            options['body'] = JSON.stringify(options['body']);
        }
    }
    if(data['full_path']){
        url = data['full_path'];
    }
    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then(response =>  {
            return response.json();
        })
        .then((response) => {
            if(response.code == 401) {
                AccountMetaData.logOut();
                return reject(response)
            }
            if(response.code && response.code != 200 || response.status_code && response.status_code != 200) return reject(response);
            return resolve(response);
        }).catch((err) => {
            return reject({
                code: 500,
                err: err
            });
        })
    })
}

export function requestApiFb(data = {}){
    let url = 'https://graph.facebook.com/v2.12'+data['url'];
    let options = {
        method: data.method ? data.method : 'GET'
    };
    if(options.method.toLocaleLowerCase() != 'get'){
        options['body'] = data.data;
    }
    options['headers'] = {
        'Authorization' : dimens['auth.token.facebook']
    }
    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then(response =>  {
            return response.json();
        })
        .then((response) => {
            if(response.error) return reject(response.error);
            // if(response.code && response.code != 200 || response.status_code && response.status_code != 200) return reject(response);
            return resolve(response);
        }).catch((err) => {
            return reject({
                code: 500,
                err: err
            });
        })
    })
}