import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip} from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import { LoadSpinner } from '../../../widgets';

export default class withDraws extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { data: [], total: 0},
            valuesFilter: {}
        };
        this.page = 1;
        this.limit = 20;
    }
    componentWillMount = async () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let query = `limit=${this.limit}&page=${this.page}`;
            for (let [key, value] of Object.entries(this.state.valuesFilter)) {
                query += `&${key}=${value}`;
            }
            let res = await fetchData({
                url: `api/v1/affiliate/withdraws?${query}`
            });

            this.setState({ data: res,loading: false});
        } catch (err) {
            this.setState({ loading: false });
        }
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 300)
    }
    handleFilter = (e) => {
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter }, () => { this.getListData('reset') });
    }
    render() {
        return (
            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title d-flex align-items-center">
                                    <Tooltip
                                        title="Quay lại"
                                    >
                                        <Link to="/affiliate" className="si si-arrow-left-circle mr-10 text-black"></Link>
                                    </Tooltip>
                                    Lịch sử rút tiền
                                </h3>
                            </div>
                            <div className="block-content">
                                <div className="table-responsive">
                                    <LoadSpinner show={this.state.loading} />
                                    <table className="table table-striped table-vcenter table-text-center table-white-space">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Ngày rút tiền</th>
                                                <th>Thông tin chuyển khoản</th>
                                                <th>Số tiền</th>
                                                <th>Trạng thái</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.data.length >  0 ? this.state.data.data.map((item, i) => {
                                                    return <tr key={i}>
                                                        <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                        <td>{moment(item.created_time).format('HH:mm DD/MM/YYYY')}</td>
                                                        <td>
                                                            <textarea className="form-control" disabled style={{maxWidth: "300px", margin: "auto"}}>{item.info_payment}</textarea>
                                                        </td>
                                                        <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td>
                                                        <td>
                                                            {
                                                                item.status == 1 ?
                                                                <span className="badge badge-success">Đã duyệt</span>
                                                                : item.status == 2 ?
                                                                <span className="badge badge-danger">Bị từ chối</span>
                                                                : <span className="badge badge-primary">Chờ duyệt</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                }) : <tr><td colSpan={11}>Chưa có dữ liệu</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="block-content d-flex" style={{justifyContent: "flex-end"}}>
                                    <div></div>
                                    {
                                        this.state.data.total > 0 ?
                                        <Pagination
                                            total={this.state.data.total}
                                            pageSize={this.limit}
                                            current={this.page}
                                            onChange={(current) => {
                                                this.page = current;
                                                this.getListData();
                                            }}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
