import React, { Component } from 'react';
import { fetchData } from '../../../lib/apis';
import moment from 'moment';
import { Button, Input, Form, Modal, Table, Select, Radio, InputNumber, Tag } from 'antd';
import qs from "qs";

export default class ServiceAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            totalData: 0,
            services: []
        };
    }
    componentWillMount = async () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            let res = await fetchData({
                url: `api/v1/admin/service`
            });

            this.setState({ listData: res.data, loading: false  });
        } catch (err) {
            this.setState({ loading: false });
        }
    }
    updateService = async (values) => {
        this.setState({ loadingForm: true });
        try {
            if(values.banking) values.banking = values.banking.split("\n");
            if(values.packages) values.packages = JSON.parse(values.packages);

            let res = await fetchData({
                url: `api/v1/admin/service`,
                method: 'post',
                data: values
            });
            this.setState({ visibleForm: false });
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công!',
                title: 'Thông báo',
                type: 'success'
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Thông báo',
                type: 'error'
            })
        } finally{
            this.setState({ loadingForm: false });
        }
    }
    render() {
        return (
            <div className="content">
                <Modal
                    title="Cập nhập"
                    footer={null}
                    onCancel={() => this.setState({ visibleForm: false })}
                    visible={this.state.visibleForm}
                    centered
                    width={600}
                >
                    <Form
                        onFinish={this.updateService}
                        ref={(evt) => this.form = evt }
                        initialValues={{
                            show_discount: true
                        }}
                    >
                        <div className='row'>
                            <Form.Item name="domain" label="Domain" className='col-4'>
                                <Input />
                            </Form.Item>
                            <Form.Item name="type_service" label="Loại" className='col-4'>
                                <Input />
                            </Form.Item>
                            <Form.Item name="status" label="Trạng thái" className='col-4'>
                                <Select>
                                    <Select.Option value={1}>Kích hoạt</Select.Option>
                                    <Select.Option value={2}>Không kích hoạt</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="ver_ext" label="Ver ext" className='col-4'>
                                <Input />
                            </Form.Item>
                            <Form.Item name="short_code" label="Short code" className='col-4'>
                                <Input />
                            </Form.Item>
                            <Form.Item name="site_scan" label="Site scan" className='col-4'>
                                <Input />
                            </Form.Item>
                            <Form.Item name="link_support" label="Link support" className='col-6'>
                                <Input />
                            </Form.Item>
                            <Form.Item name="link_ext_token" label="Link ext token" className='col-6'>
                                <Input />
                            </Form.Item>
                            <Form.Item name="show_discount" label="Show discount" className='col-6'>
                                <Radio.Group>
                                    <Radio value={false}>Không hiển thị</Radio>
                                    <Radio value={true}>Hiển thị</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name="price_one_uid" label="Price one uid" className='col-6'>
                                <InputNumber className='w-100'/>
                            </Form.Item>
                        </div>
                        <Form.Item name="owner_roles" label="Quản trị">
                            <Select mode="tags" maxTagCount={2}
                                allowClear
                            >

                            </Select>
                        </Form.Item>
                        <Form.Item name="banking" label="Banking">
                            <Input.TextArea rows={3}/>
                        </Form.Item>
                        <Form.Item name="packages" label="Packages">
                            <Input.TextArea rows={3}/>
                        </Form.Item>
                        <div className="d-flex justify-content-end">
                            <Button danger onClick={() => this.setState({ visibleForm: false })} disabled={this.state.loadingForm}>Đóng</Button>
                            <Button htmlType="submit" type="primary" className="ml-2" loading={this.state.loadingForm}>Xác nhận</Button>
                        </div>
                    </Form>
                </Modal>
                <div className="row">
                    <div className="col-md-12">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Quản lý domain</h3>
                            </div>
                            <div className="block-header">
                                <Button
                                    onClick={() => this.setState({ visibleForm: true })}
                                    type="primary"
                                >Thêm mới</Button>
                            </div>
                            <div className="block-content">
                                <Table 
                                    dataSource={this.state.listData}
                                    loading={this.state.loading}
                                    columns={[
                                        {
                                            title: "Stt",
                                            render: (value, record, i) => i
                                        },
                                        {
                                            title: "Site",
                                            dataIndex: "domain"
                                        },
                                        {
                                            title: "Trạng thái",
                                            dataIndex: "status",
                                            render: (value, record) => {
                                                return <Tag color={value !== 1 && "red"}>{value == 1 ? "Kích hoạt" : "Không kích hoạt"}</Tag>
                                            }
                                        },
                                        {
                                            title: "Loại",
                                            dataIndex: "type_service"
                                        },
                                        {
                                            title: "Code",
                                            dataIndex: "short_code"
                                        },
                                        {
                                            title: "Ver ext",
                                            dataIndex: "ver_ext"
                                        },
                                        {
                                            title: "Thao tác",
                                            align: "right",
                                            render: (value, record) => {
                                                return <>
                                                    <Button size='small'
                                                        onClick={() => {
                                                            this.setState({ visibleForm: true }, () => {
                                                                setTimeout(() => {
                                                                    if(this.form) this.form.setFieldsValue({
                                                                        ...record,
                                                                        packages: JSON.stringify(record.packages),
                                                                        banking: record.banking.join("\n")
                                                                    })
                                                                })
                                                            })
                                                        }}
                                                    ><i className='fa fa-edit'></i></Button>
                                                </>
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
